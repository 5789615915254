
import { Component, Vue } from 'vue-property-decorator';
import { TimeSlotSelection } from '@/types/time-slots';
import TimeSelection from '@/pages/shared/CampaignWizard/components/Time/components/TimeSelection.vue';
import PriceSummary from '@/pages/shared/CampaignWizard/components/PriceSummary.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import DatePickerInput from '@/ui-components/DatePickerInput/DatePickerInput.vue';
import ComingSoon from '@/ui-components/ComingSoon/ComingSoon.vue';
import WizardHeader from '@/pages/shared/CampaignWizard/components/WizardHeader.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import { LocationData, LocationPrice } from '@/types/locations';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { UpdateTime } from '@/store/modules/campaign-wizard/types';
import { rules } from '@/validation-rules';
import moment from 'moment';
import { CampaignFrequency } from '@/types/campaigns';
import LocationPriceDialog
  from '@/pages/store-owner/CreateCampaignWizard/components/CampaignTime/components/LocationPriceDialog.vue';
import TabsItems from '@/ui-components/Tabs/TabsItems.vue';
import TabItem from '@/ui-components/Tabs/TabItem.vue';

@Component({
  components: {
    LocationPriceDialog,
    PriceSummary,
    DatePickerInput,
    TextField,
    IButton,
    WizardHeader,
    ComingSoon,
    TimeSelection,
    TabsItems,
    TabItem,
  },
})
export default class CampaignTime extends Vue {
  public valid = false;

  public TODAY = moment().add('days', 1).format('YYYY-MM-DD');

  public requiredRule = rules.required('This field is required');
  activeTabIndex = 0;
  public durationRules = [
    rules.onlyPositiveNumberOptional(this.$t('only_positive_number_allowed').toString()),
    rules.maxLengthOptional(
      2,
      this.$t('max_length', {
        field: this.$t('duration_small').toString(),
        maxLength: 99,
      }).toString()
    ),
  ];
  public startDateRules = [this.requiredRule, rules.minDate(this.TODAY, 'Please choose date after today')];

  public calendarIcon = '/assets/icons/calendar.svg';

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedLocations!: LocationData[];

  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedTimeSlots!: TimeSlotSelection;

  @Getter('locationPrices', { namespace: namespaces.CampaignWizardModule })
  public locationPrices!: Record<LocationData['ID'], LocationPrice>;

  @Action('updateTime', { namespace: namespaces.CampaignWizardModule })
  public updateTime!: UpdateTime;

  public get selectedFrequency() {
    return this.campaignWizardSelectedTimeSlots.frequency as CampaignFrequency;
  }

  public set selectedFrequency(frequency: CampaignFrequency) {
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      frequency,
    });
  }

  public get startDate() {
    return this.campaignWizardSelectedTimeSlots.startDate as string;
  }

  public set startDate(startDate: string) {
    const resetEndDate = { endDate: undefined };
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      ...resetEndDate,
      startDate,
    });
  }

  public get endDate() {
    return this.campaignWizardSelectedTimeSlots?.endDate as string;
  }

  public set endDate(endDate: string) {
    const resetDuration = { duration: undefined };
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      ...resetDuration,
      endDate,
    });
  }

  get minEndDate() {
    return moment(this.startDate || this.TODAY).add('day', 1).format('YYYY-MM-DD')
  }

  public get duration() {
    return this.campaignWizardSelectedTimeSlots.duration as number;
  }

  public set duration(duration: number) {
    const resetEndDate = { endDate: undefined };
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      ...resetEndDate,
      duration,
    });
  }

  get campaignHasEndDateOrDuration() {
    return this.activeTabIndex === 0 ? this.duration : this.endDate
  }

  get showPriceSummary() {
    const isAtLeastOneLocationWithRegulatorSelected = this.campaignWizardSelectedLocations.some(selectedLocation => selectedLocation.REGULATOR_ID);
    const isAllLocationPricesFilled = this.campaignWizardSelectedLocations.every(selectedLocation => this.locationPrices?.[selectedLocation.clusterId]?.BASE_PRICE);
    return isAtLeastOneLocationWithRegulatorSelected && isAllLocationPricesFilled
  }

  public timeOptions = 'fixed';

  public previousStep() {
    this.$emit('previous-step');
  }

  public nextStep() {
    this.$emit('next-step');
  }

  openPriceLocationPriceDialog() {
    const locationPriceDialog = this.$refs.locationPriceDialog as LocationPriceDialog;
    locationPriceDialog.open();
  }


  setTabValues() {
    if (this.endDate) {
      this.activeTabIndex = 1
    }
  }

  mounted() {
    this.setTabValues();
    if (this.campaignWizardSelectedLocations.some(selectedLocation => selectedLocation.REGULATOR_ID)) {
      this.openPriceLocationPriceDialog();
    }
  }
}
