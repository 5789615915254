
import { Component, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { LocationData, LocationPrice } from '@/types/locations';
import { onlyNumbersRegex } from '@/validation-rules';
import { UpdateLocationPrices } from '@/store/modules/campaign-wizard/types';
import { adSpaceTypesIcons } from '@/statics/ad-spaces';
import LocationCityAndAddress from '@/shared-components/LocationCityAndAddress.vue';
import { createNumberMask } from 'text-mask-addons';
@Component({
  name: "LocationPriceDialog",
  components: {IButton, IDialog,TextField,LocationCityAndAddress},
})
export default class LocationPriceDialog extends Vue {
  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Getter('locationPrices', { namespace: namespaces.CampaignWizardModule })
  public locationPricesVuex!: Record<LocationData['ID'],LocationPrice>;

  @Action('updateLocationPrices', { namespace: namespaces.CampaignWizardModule })
  public updateLocationPrices!: UpdateLocationPrices;

  public isOpen = false;
  public locationPrices = {} as Record<LocationData['ID'], LocationPrice>;
  public viewAdSpaceTypesIcons = adSpaceTypesIcons;
  currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
    integerLimit: 9
  });

  public open() {
    this.isOpen = true;
  }

  public close() {
    if(this.isFormFilled) {
      const newLocationPrices = Object.entries(this.locationPrices).reduce((locationPrices, [screenClusterId, locationPrice]) => {
        return {
          ...locationPrices,
          [screenClusterId]: { BASE_PRICE: Number(`${locationPrice.BASE_PRICE}`.replaceAll(',', '')) }
        }
      }, {})
      this.updateLocationPrices(newLocationPrices)
      this.isOpen = false;
    }
  }
  get isFormFilled(){
    return Object.values(this.locationPrices).every((locationPrice)=>onlyNumbersRegex.test(`${locationPrice.BASE_PRICE}`||''))
  }
  created(){
    this.locationPrices = this.selectedLocations.reduce((selectedLocations,selectedLocation)=>{
      return { ...selectedLocations,
        [selectedLocation.clusterId]: {
          BASE_PRICE: this.locationPricesVuex?.[selectedLocation.clusterId]?.BASE_PRICE || ''
        }
      }
    },{})
  }

}
