
import { Component, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import MediaUploadGuidelines from '@/shared-components/MediaUploadGuidelines.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import { MediaData, MediaFile, MediaOption } from '@/types/media';
import { rules } from '@/validation-rules';
import ScreenSpecs from '@/shared-components/ScreenSpecs.vue';
import CampaignNameDialog from '@/pages/shared/CampaignWizard/components/AdContent/components/CampaignNameDialog.vue';
import WizardHeader from '@/pages/shared/CampaignWizard/components/WizardHeader.vue';
import UploadNow from '@/pages/shared/CampaignWizard/components/AdContent/components/UploadNow.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { SaveCampaign, UpdateAdvertiserName, UpdateMedia } from '@/store/modules/campaign-wizard/types';
import { LocationData } from '@/types/locations';
import { Campaign } from '@/types/campaigns';
import { ShowSnackbar } from '@/types/snackbar';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

@Component({
  components: {
    IButton,
    TextField,
    ScreenSpecs,
    UploadNow,
    WizardHeader,
    CampaignNameDialog,
    MediaUploadGuidelines
  },
})
export default class AdContent extends Vue {
  public valid = false;
  public isUploading = false;
  public isCreateCampaignLoading = false;
  public advertiserNameHint = this.$t('to_help_you_to_identify_campaign_easily').toString();
  uploadNow = 'UPLOAD_NOW';

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Getter('media', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardMedia!: MediaData;

  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;

  @Getter('name', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardCampaignName!: string;


  @Getter('savedCampaign', { namespace: namespaces.CampaignWizardModule })
  public savedCampaign!: Campaign;

  @Action('updateMedia', { namespace: namespaces.CampaignWizardModule })
  public updateMedia!: UpdateMedia;

  @Action('updateAdvertiserName', { namespace: namespaces.CampaignWizardModule })
  public updateAdvertiserName!: UpdateAdvertiserName;

  @Action('saveStoreOwnerCampaign', { namespace: namespaces.CampaignWizardModule })
  public saveStoreOwnerCampaign!: SaveCampaign;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Mutation('syncRequiredMediaResolutions', { namespace: namespaces.CampaignWizardModule })
  public syncRequiredMediaResolutions!: any;

  public get selectedMediaOption() {
    return this.campaignWizardMedia.option!;
  }

  public set selectedMediaOption(option: MediaOption) {
    this.updateMedia({
      ...this.campaignWizardMedia,
      option,
    });
  }

  public get selectedMediaFiles(): MediaFile[] {
    return this.campaignWizardMedia.files!;
  }

  public set selectedMediaFiles(files: MediaFile[]) {
    this.updateMedia({
      ...this.campaignWizardMedia,
      files,
    });
  }

  public get advertiserName() {
    return this.campaignWizardCampaignName;
  }


  public set advertiserName(advertiserName: string) {
    this.updateAdvertiserName(advertiserName);
  }

  public get advertiserNameRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_advertiser_name') as string),
      rules.maxLength(30, this.$t('max_length', {
        field: this.$t('advertiser_small').toString(),
        maxLength: 30
      }).toString()),
    ];
  }

  public get isMediaOptionSelected(): boolean {
    if (this.selectedMediaOption === this.uploadNow) {
      return Boolean(this.selectedMediaFiles && this.selectedMediaFiles.length)
    }

    return Boolean(this.selectedMediaOption);
  }

  public get disableCreateCampaign() {
    return !this.valid || this.isUploading ||
      !this.isMediaOptionSelected || !this.isMediaUploadsCompleted || this.isCreateCampaignLoading
  }


  public handleOptionSelection(value: string) {
    if (value === this.uploadNow) {
      this.syncRequiredMediaResolutions();
    } else {
      this.selectedMediaFiles = [];
    }
  }

  public async mounted() {
    this.syncRequiredMediaResolutions();
    this.selectedMediaOption = this.uploadNow as MediaOption
  }

  async createCampaign() {
    try{
      this.isCreateCampaignLoading = true;
      await this.saveStoreOwnerCampaign();
      await this.$router.push({ ...storeOwnerRouteNames.PLAYLIST })
    }
    catch {

    }
    finally {
      this.isCreateCampaignLoading = false;
    }

  }

  public back() {
    this.$emit('previous-step');
  }

  get isMediaUploadsCompleted(): boolean {
    if (this.selectedMediaOption === this.uploadNow) {
      return Boolean(this.selectedMediaFiles.length)
        && this.selectedMediaFiles.every(media => Boolean(media.path));
    }

    return true;
  }
}
