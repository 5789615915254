
import { Component, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import Stepper from '@/ui-components/Stepper/Stepper.vue';
import StepperContent from '@/ui-components/Stepper/StepperContent.vue';
import StepperItems from '@/ui-components/Stepper/StepperItems.vue';
import StepperStep from '@/ui-components/Stepper/StepperStep.vue';
import StepperHeader from '@/ui-components/Stepper/StepperHeader.vue';
import StepperHeaderResponsive from '@/ui-components/Stepper/StepperHeaderResponsive.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import Whatsapp from '@/pages/shared/CampaignWizard/components/Whatsapp.vue';
import Locations from './components/Locations/Locations.vue';
import CampaignTime from './components/CampaignTime/CampaignTime.vue';
import AdContent from './components/AdContent/AdContent.vue';
import PriceSummary from '@/pages/shared/CampaignWizard/components/PriceSummary.vue';
import MediaUploadGuidelines from '@/shared-components/MediaUploadGuidelines.vue';
import { LocationData } from '@/types/locations';
import { storeOwnerSteps } from '@/statics/campaign-wizard';
import {
  CheckoutCampaign,
  DeselectLocation,
  InitNewCampaign,
  UpdateStep,
} from '@/store/modules/campaign-wizard/types';
import { TimeSlotSelection } from '@/types/time-slots';
import { ShowSnackbar } from '@/types/snackbar';
import RightPaymentSection from '@/pages/shared/CampaignWizard/components/Payment/components/RightPaymentSection.vue';

@Component({
  name:'CreateCampaignWizard',
  components: {
    RightPaymentSection,
    Stepper,
    StepperContent,
    StepperItems,
    StepperStep,
    StepperHeader,
    Whatsapp,
    Locations,
    CampaignTime,
    IButton,
    AdContent,
    StepperHeaderResponsive,
    PriceSummary,
    MediaUploadGuidelines,
  },
})
export default class CreateCampaignWizard extends Vue {
  @Getter('step', { namespace: namespaces.CampaignWizardModule })
  public currentStep!: number;
  public loading = true;

  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;
  @Getter("selectedLocations", { namespace: namespaces.CampaignWizardModule })
  public selectedLocationsVuex!: LocationData[];

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedLocations!: LocationData[];

  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedTimeSlots!: TimeSlotSelection;

  @Action('updateStep', { namespace: namespaces.CampaignWizardModule })
  public updateStep!: UpdateStep;

  @Action('initNewCampaign', { namespace: namespaces.CampaignWizardModule })
  public initNewCampaign!: InitNewCampaign;
  @Action("deselectLocation", { namespace: namespaces.CampaignWizardModule })
  public deselectLocation!: DeselectLocation;
  @Action('checkoutCampaign', { namespace: namespaces.CampaignWizardModule })
  public checkoutCampaign!: CheckoutCampaign;
  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Action('resetCampaign', { namespace: namespaces.CampaignWizardModule })
  public resetCampaign!: InitNewCampaign;
  public selectedLocations: LocationData[] = [];
  public isPublic = true;
  public get steps() {
    return storeOwnerSteps.filter(
      ({ onlyPublic }) =>
        this.isPublic || (!onlyPublic || (this.isPublic && onlyPublic))
    );
  }

  public initWizard() {
    this.initNewCampaign();
    this.isPublic = !this.isAuthenticated;
  }

  public created() {
    this.resetCampaign()
    this.initWizard();
    this.initNewCampaign();
  }

  public previousStep() {
    this.updateStep(this.currentStep - 1);
  }

  public nextStep() {
    this.updateStep(this.currentStep + 1);
  }

  public goToStep(step: number) {
    this.updateStep(step);
  }
}
